import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { DiscoverMoreCarousel } from './DiscoverMoreCarousel';

import CoverForDamageImage from '@resources/images/ev-charger-carousel-damage-cover.png';
import EvChargerExpertsImage from '@resources/images/ev-charger-carousel-experts.png';
import SustainabilityImage from '@resources/images/ev-charger-carousel-sustainability.png';
import { mqMediumUp } from '@utils/mediaQuery';

type Card = {
  imageSrc: string;
  title: string;
  description: string;
};

const Section = styled.div`
  position: relative;
  z-index: 1;
  padding: 96px 0;
  background: ${({ theme }) => theme.core.color.brand.ovo};
  ${mqMediumUp(`
    padding: 184px 0 200px;
  `)};
`;

const cards: Card[] = [
  {
    imageSrc: CoverForDamageImage,
    title: 'Cover for damage and fire',
    description:
      'If accidents happen and your charger stops working or is unsafe, you’re protected with accidental damage cover. And you’re insured against fire damage to your charger, too. Terms apply.',
  },
  {
    imageSrc: EvChargerExpertsImage,
    title: 'We’re EV charger experts',
    description:
      'OVO fit them, power them and champion the shift to electric. So who better to cover your charger?',
  },
  {
    imageSrc: SustainabilityImage,
    title: 'Sustainability at the core',
    description:
      'By choosing to repair over replace, you can reduce waste. You’ll keep your charger working and avoid adding to landfill as much as possible.',
  },
];

export const DiscoverMoreSection = () => {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  if (!rendered) {
    return null;
  }

  return (
    <Section>
      <DiscoverMoreCarousel cards={cards} />
    </Section>
  );
};
