import { CTAButton } from '@ovotech/element';
import { navigate } from 'gatsby';

export const QuotesPlatformHome = () => {
  return (
    <>
      Quotes Platform Home<CTAButton onClick={() => navigate('/details')}>Click me</CTAButton>
    </>
  );
};
