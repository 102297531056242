import { useDecision } from '@optimizely/react-sdk';
import { PageProps } from 'gatsby';

import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { Beyond as BeyondModule } from '@src/modules';
import { QuotesPlatformBeyond } from '@src/modules/Beyond/QuotesPlatformBeyond';

const Beyond = (props: PageProps) => {
  const [{ enabled: isQuotesPlatform }] = useDecision(OptimizelyFeatureFlag.QUOTE_PLATFORM_ROLLOUT);

  if (isQuotesPlatform) {
    return <QuotesPlatformBeyond />;
  }

  return <BeyondModule {...props} />;
};

export default Beyond;
