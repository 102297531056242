import { Heading4, Margin, P } from '@ovotech/element';
import styled, { css } from 'styled-components';

import { mqMediumUp } from '@utils/mediaQuery';

const StyledWhatPeopleAreSayingSection = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    background-color: ${theme.core.color.brand.white};
    padding: calc(2 * ${theme.core.space[15]}) calc(${theme.core.space[8]});

    ${mqMediumUp(`
      text-align: center;
    `)};
  `
);

const HeadingMobile = styled(Heading4)(
  ({ theme }) => css`
    ${mqMediumUp(`
      font-weight: ${theme.core.fontWeight.bold};
      display: none;
    `)};
  `
);

const HeadingDesktop = styled(Heading4)(
  ({ theme }) => css`
    font-weight: ${theme.core.fontWeight.bold};
    display: none;

    ${mqMediumUp(`
    display: block;
  `)};
  `
);

const StyledParagraph = styled(P)(
  ({ theme }) => css`
    display: flex;
    color: ${theme.core.color.brand.ovo};
    font-size: 48px;
    letter-spacing: -2.56px;
    line-height: 100%;
    font-weight: ${theme.core.fontWeight.bold};
    max-width: 832px;

    ${mqMediumUp(`
      align-self: center;
      font-size: 64px;
      line-height: 95%;
    `)};
  `
);

const StyledSpan = styled.span(
  ({ theme }) => css`
    color: ${theme.core.color.brand.ovo};
    font-weight: ${theme.core.fontWeight.bold};

    ${mqMediumUp(`
      align-self: center;
  `)};
  `
);

export const WhatPeopleAreSayingSection = () => {
  return (
    <StyledWhatPeopleAreSayingSection>
      <HeadingMobile>What people are saying</HeadingMobile>
      <HeadingDesktop>What people are saying about our service</HeadingDesktop>
      <Margin vertical={3} />
      <StyledParagraph>
        “ Very helpful, efficient and you’ve done a brilliant job at short notice. Would definitely
        recommend. ”
      </StyledParagraph>
      <Margin vertical={4} />
      <StyledSpan>Sara Jane</StyledSpan>
    </StyledWhatPeopleAreSayingSection>
  );
};
