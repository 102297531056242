import styled from 'styled-components';

import { mqMediumDown } from '@utils/mediaQuery';

export const InnerContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 80%;
  ${mqMediumDown(`width: 100%`)};
`;
