import styled from 'styled-components';
import { Heading1, Heading4, TextLink } from '@ovotech/element';
import React from 'react';

import { InnerContainer } from './ styled';

import HowItWorksBackground from '@resources/images/howItWorksBackground.png';
import { mqMediumDown, mqWideMediumDown } from '@utils/mediaQuery';

const HowItWorksBackgroundContainer = styled.div`
  ${({ theme }) => `
      padding: 216px 0 350px;
      background-color: ${theme.core.color.brand.ovo};
      position: relative;
      
      ${mqWideMediumDown(`padding-top: 100px;`)};
      ${mqMediumDown(`padding-bottom: 290px;`)};
      
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-size: cover;
        background-position: 35% 0;
        background-image: url(${HowItWorksBackground});
        opacity: 0.8;
        background-repeat: no-repeat;
        height: 600px;
        
        ${mqWideMediumDown(`height: 300px; background-position: 100% 0;`)};
        ${mqMediumDown(`height: 220px;`)};
      }
  `}
`;

const HowItWorksContainer = styled(InnerContainer)`
  ${mqMediumDown(`padding: 0 12px;`)};
`;

const StyledHeading1 = styled(Heading1)`
  ${({ theme }) => `
      font-size: 64px;
      line-height: 1;
      letter-spacing: -3px;
      width: 900px;
      max-width: 100%;
      color: ${theme.core.color.brand.white};
      margin: ${theme.core.space[3]} 0 ${theme.core.space[12]};
          
      ${mqWideMediumDown(`font-size: 48px; width: auto;`)};
  `}
`;

const HowItWorksItems = styled.div`
  ${({ theme }) => `
      padding-left: ${theme.core.space[8]};
  `}
`;

const HowItWorksItem = styled.div`
  ${({ theme }) => `
      background-color: ${theme.core.color.brand.white};
      padding: ${theme.core.space[6]};
      margin-bottom: ${theme.core.space[4]};
      max-width: 650px;
      border-radius: 16px;
      position: relative;
      
      &::before {
        content: '';
        position: absolute;
        left: -32px;
         height: 20px;
         width: 20px;
         top: 45%;
         border-radius: 50%;
         border: 4px solid ${theme.core.color.brand.white};
         background-color: ${theme.core.color.brand.ovo};
         z-index: 2;
      }
      
      &:not(:first-child){
         &::after {
          content: '';
          left: -23px;
          top: -65px;
          bottom: 0;
          width: 2px;
          position: absolute;
          background: repeating-linear-gradient(
            to bottom,
            #95C59A,
            #95C59A 7px,
            transparent 7px,
            transparent 8px
          );
          clip-path: inset(0 round 2px);
        }
      }
      
      &:last-child {
        &::after {
          bottom: 50%;
        }
      }
  `}
`;

const HowItWorksItemTitle = styled(Heading4)`
  ${({ theme }) => `
      font-size: 20px;
      line-height: 1.2;
      color: #00261C;
      margin-bottom: ${theme.core.space[2]};
  `}
`;

const StyledHeading4 = styled(Heading4)`
  ${({ theme }) => `
        color: ${theme.core.color.brand.white};
    `};
`;

const HowItWorksItemLink = styled(TextLink)`
  font-size: 20px;
  line-height: 1.2;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  color: #044d29;
  cursor: pointer;
`;

const HowItWorksItemDescription = styled.div``;

type HowItWorksProps = {
  onContinue: () => void;
};

type HowItWorksStep = {
  title: React.ReactNode;
  description: string;
};

export const HowItWorks = ({ onContinue }: HowItWorksProps) => {
  const howItWorksSteps: HowItWorksStep[] = [
    {
      title: <HowItWorksItemLink onClick={onContinue}>Book your repair online</HowItWorksItemLink>,
      description: 'Tell us about the problem at home, it should only take a few minutes.',
    },
    {
      title: 'An engineer will contact you within 24 hours',
      description:
        'If you book before 1pm, they’ll aim to contact you the same day. And if not, within 24 hours. One of our expert engineers will then find a time to visit that suits you.',
    },
    {
      title: 'We’ll fix your emergency',
      description: 'An engineer will visit your home to fix the problem and answer any questions.',
    },
    {
      title: 'Stay protected with ongoing cover and unlimited callouts',
      description:
        'Your monthly fee covers your boiler, plumbing, home electrics, drains and more. And with a 24/7 helpline, we’re always here when you need us.',
    },
  ];

  return (
    <HowItWorksBackgroundContainer>
      <HowItWorksContainer>
        <StyledHeading4>How Home Recover works.</StyledHeading4>
        <StyledHeading1>Tell us what’s happened, we’ll take care of the rest.</StyledHeading1>
        <HowItWorksItems>
          {howItWorksSteps.map((step, index) => (
            <HowItWorksItem key={index}>
              <HowItWorksItemTitle>{step.title}</HowItWorksItemTitle>
              <HowItWorksItemDescription>{step.description}</HowItWorksItemDescription>
            </HowItWorksItem>
          ))}
        </HowItWorksItems>
      </HowItWorksContainer>
    </HowItWorksBackgroundContainer>
  );
};
