import { PageProps } from 'gatsby';
import React from 'react';
import { useDecision } from '@optimizely/react-sdk';

import { useAppContext } from '@contexts/AppContext';
import { useCustomMixpanel } from '@services/Mixpanel';
import { SummaryPage } from '@src/modules';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { QuotesPlatformSummaryPage } from '@src/modules/Summary/QuotesPlatformSummaryPage';

const Summary = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();
  const { verifyNoSignupInLocalStorage, storeSignupInLocalStorage } = useAppContext();

  const [{ enabled: isQuotesPlatform }] = useDecision(
    OptimizelyFeatureFlag.QUOTE_PLATFORM_ROLLOUT,
    { autoUpdate: true }
  );

  if (isQuotesPlatform) {
    return <QuotesPlatformSummaryPage />;
  }

  return (
    <SummaryPage
      {...props}
      mixpanel={mixpanel}
      verifyNoSignupInLocalStorage={verifyNoSignupInLocalStorage}
      storeSignupInLocalStorage={storeSignupInLocalStorage}
    />
  );
};

export default Summary;
