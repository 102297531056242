export type WhatYouGetCardContent = {
  heading: string;
  bulletPoints: string[];
};

export const whatYouGetCardContent: WhatYouGetCardContent[] = [
  {
    heading: 'Boiler and heating',
    bulletPoints: [
      'No heating or hot water',
      'Boiler leaks',
      'Boiler pressure faults',
      'Radiator faults or leaks',
    ],
  },
  {
    heading: 'Plumbing',
    bulletPoints: [
      'Uncontrollable water leaks',
      'Tap, pipe and toilet leaks',
      'Toilet flushes not working',
    ],
  },
  {
    heading: 'Home electrics',
    bulletPoints: [
      'Full loss of power',
      'Circuit breaker tripping',
      'Light switch fault',
      'Socket fault',
    ],
  },
  {
    heading: 'Internal drains',
    bulletPoints: ['Blocked internal drain or wastepipe', 'Leaking internal drain or wastepipe'],
  },
];
