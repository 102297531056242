import styled from 'styled-components';
import { ReactNode, useEffect, useState } from 'react';

import { BenefitsCarousel } from './BenefitsCarousel';
import { BenefitsGrid } from './BenefitsGrid';

import { mqLargeUp } from '@utils/mediaQuery';
import EvInsuranceBenefit from '@resources/images/EvInsuranceBenefit.png';
import EvReplacementBenefit from '@resources/images/EvReplacementBenefit.png';
import EvVandalismBenefit from '@resources/images/EvVandalismBenefit.png';
import EvFastResponseBenefit from '@resources/images/EvFastResponseBenefit.png';
import { SupRef } from '@components/Footer';
import { evChargerCoverWarrantyFootnote } from '@constants/footnotes';
import { useMediaIs } from '@hooks/useMediaIs';

const Root = styled.div`
  padding: 64px 0;
  ${mqLargeUp(`
    padding: 0 16px 55px;
    margin-left: auto;
    margin-right: auto;
  `)};
`;

type Card = {
  imageSrc: string;
  title: string;
  description: ReactNode;
};

const cards: Card[] = [
  {
    imageSrc: EvReplacementBenefit,
    title: 'Brand new replacements',
    description: (
      <>
        If your charger can’t be fixed, we’ll install a new one for you. So you get peace of mind,
        even if you are outside warranty.
        <SupRef footnote={evChargerCoverWarrantyFootnote} />
      </>
    ),
  },
  {
    imageSrc: EvFastResponseBenefit,
    title: 'Fast response',
    description:
      'We’ll aim to be with you within 24 hours of your call. And you can speak to our team of specialists 24/7, all year round.',
  },
  {
    imageSrc: EvVandalismBenefit,
    title: 'Vandalism and theft cover',
    description:
      'You’ll be covered if someone else damages your charger or takes the cable from your home.',
  },
  {
    imageSrc: EvInsuranceBenefit,
    title: 'Full system insurance',
    description:
      'We cover everything from wiring and fuses, to switches. Labour and parts are included with no hidden costs.',
  },
];

export const BenefitsSection = () => {
  const isDesktop = !useMediaIs('lg', 'max');
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, []);

  if (!rendered) {
    return null;
  }

  return (
    <Root>{isDesktop ? <BenefitsGrid cards={cards} /> : <BenefitsCarousel cards={cards} />}</Root>
  );
};
