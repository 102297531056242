import {
  Li,
  Margin,
  Modal,
  P,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Ul,
  TextLink,
  theme,
} from '@ovotech/element';
import styled from 'styled-components';

import { BulletWithIcon } from '@components/BulletWithIcon';
import { EV_CHARGER_TERMS } from '@fixtures/planEvCharger';

const IncludedLi = styled(Li)`
  margin-left: 15px;
`;

interface WhatsIncludedEvChargerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WhatsIncludedEvChargerModal = ({
  isOpen,
  onClose,
}: WhatsIncludedEvChargerModalProps) => {
  return (
    <Modal
      title="EV Charger Cover"
      isOpen={isOpen}
      onClose={onClose}
      data-testid="evcc-whats-included"
    >
      <TabPanel>
        <Tabs>
          <TabList>
            <Tab>Included</Tab>
            <Tab>Not Included</Tab>
          </TabList>
          <TabPanel>
            <BulletWithIcon
              flexStart
              icon={{ name: 'boilerEngineer', size: 20 }}
              text="We cover:"
            />
            <Ul>
              <IncludedLi>AC EV charger units up to 22kW output or less</IncludedLi>
              <IncludedLi>EV charger unit electrical wiring</IncludedLi>
              <IncludedLi>EV charger unit fuses</IncludedLi>
              <IncludedLi>the EV charger unit isolation switch</IncludedLi>
              <IncludedLi>EV charger switchgear</IncludedLi>
              <IncludedLi>the EV charging cable you use at home</IncludedLi>
              <IncludedLi>theft from the home of the EV charging unit or charging cable</IncludedLi>
            </Ul>
            <BulletWithIcon
              flexStart
              icon={{ name: 'home', size: 20, color: theme.core.color.brand.black }}
              text="For incidents at home:"
            />
            <P>
              You’re covered for operational failure or anything that affects safe use of the
              hardware caused by:
            </P>
            <Ul>
              <IncludedLi>accidental damage</IncludedLi>
              <IncludedLi>fire</IncludedLi>
              <IncludedLi>faults</IncludedLi>
              <IncludedLi>vandalism</IncludedLi>
            </Ul>
            <P>
              This policy is for one EV charger unit and one charging cable only. You can’t claim
              for incidents that happen away from home.
            </P>
            <Margin vertical={4} />
            <BulletWithIcon
              flexStart
              icon={{ name: 'emergency', size: 20 }}
              text="When your EV charger can’t be repaired:"
            />
            <Ul>
              <IncludedLi>
                For units under 10 years old we’ll replace the unit with one of a similar output.
                The limit is up to £2,000.
              </IncludedLi>
              <IncludedLi>
                For units aged 10 years or older, we’ll contribute £250 towards the cost of a
                replacement.
              </IncludedLi>
            </Ul>
            <P>
              For more details about what is and isn’t included in the EV Charger Cover plan, take a
              look at the{' '}
              <TextLink target="_blank" href={EV_CHARGER_TERMS}>
                cover terms and conditions.
              </TextLink>
            </P>
          </TabPanel>
          <TabPanel>
            <P>We do not cover:</P>
            <Ul>
              <IncludedLi>claims within the first 30 days</IncludedLi>
              <IncludedLi>pre-existing faults</IncludedLi>
              <IncludedLi>
                incidents that happen away from home EV charger units attached to a communal space
                or connected to an electricity meter that does not supply the home.
              </IncludedLi>
              <IncludedLi>
                damage to property (including buildings and vehicles) or injury to persons
              </IncludedLi>
              <IncludedLi>
                damage that does not affect performance and safety of the EV charger unit and
                charging cable.
              </IncludedLi>
              <IncludedLi>consumer units</IncludedLi>
              <IncludedLi>flood damage</IncludedLi>
              <IncludedLi>financial losses of any kind</IncludedLi>
              <IncludedLi>
                vehicle-to-home or vehicle-to-grid EV chargers or trial technology
              </IncludedLi>
              <IncludedLi>
                the costs to access wiring or return access points to their original state
              </IncludedLi>
              <IncludedLi>
                Wi-fi connectivity, apps, firmware or devices used to control and operate the EV
                charger unit or damage caused by disconnection from the wi-fi or electricity
                services
              </IncludedLi>
            </Ul>
            <P>
              For more details about what is and isn’t included in the EV Charger Cover plan, take a
              look at the{' '}
              <TextLink target="_blank" href={EV_CHARGER_TERMS}>
                cover terms and conditions.
              </TextLink>
            </P>
          </TabPanel>
        </Tabs>
      </TabPanel>
    </Modal>
  );
};
