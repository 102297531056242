import styled from 'styled-components';
import { ReactNode } from 'react';

import { BenefitCard } from './BenefitCard';

import { mqLargeUp } from '@utils/mediaQuery';

const Grid = styled.div`
  position: relative;
  margin-top: -80px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  max-width: ${({ theme }) => theme.core.breakpoint.large};
  ${mqLargeUp(`
    gap: 32px;
  `)};
`;

type Card = {
  imageSrc: string;
  title: string;
  description: ReactNode;
};

type BenefitsGridProps = {
  cards: Card[];
};

export const BenefitsGrid = ({ cards }: BenefitsGridProps) => {
  return (
    <Grid>
      {cards.map(({ imageSrc, title, description }, i) => (
        <BenefitCard
          key={title}
          imageSrc={imageSrc}
          title={title}
          description={description}
          withBoxShadow={i < 2}
        />
      ))}
    </Grid>
  );
};
