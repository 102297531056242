import { CoverType, ProductName, ProductStatus } from './enums';

import { Footnote } from '@components/Footer';

export type SummaryItemType =
  | 'annualBoilerService'
  | 'boilerHeatingSystem'
  | 'plumbing'
  | 'electrics'
  | 'boilerReplacement'
  | 'gasSupplyPipe'
  | 'waterSupplyPipe'
  | 'internalDrains'
  | 'externalDrains'
  | 'tapsAndToilets'
  | 'priorityHelpline';

export interface SummaryItem {
  id: SummaryItemType;
  name: string;
  icon: string;
  info: {
    withExcess: string[];
    noExcess: string[];
  };
}

type EvChargerExclusionItemType =
  | 'evChargerFirstMonth'
  | 'evChargerExistingFaults'
  | 'evChargerVehichleToHomeVehichleToGrid'
  | 'evChargerIncompatibleReplacementParts'
  | 'evChargerNonDomesticCharger'
  | 'evChargerFloodDamage'
  | 'evChargerDamageToProperty'
  | 'evChargerLossOfEarnings'
  | 'evChargerConsumerUnits'
  | 'evChargerCosmeticDamage'
  | 'evChargerAttachedToCommunalAreas'
  | 'evChargerNotMaintainedByStandards'
  | 'evChargerCostOfWiringOrRedecoration'
  | 'evChargerWifiOrApps';

type InternalPolicyExclusionItemType =
  | 'faultsRepair'
  | 'systemInhibitorTesting'
  | 'boilersServicing'
  | 'firstMonth'
  | 'firstThreeMonths'
  | 'preExisting'
  | 'underfloorHeating'
  | 'weather'
  | 'oldBoilers'
  | 'noEarlyReplacement'
  | 'excess'
  | 'debrisDamage'
  | 'disconnection'
  | 'electricalDevices'
  | 'unoccupied'
  | 'landlords'
  | 'outsideWater'
  | 'sharedDrains'
  | 'beyondRepair'
  | 'commercialBoilers'
  | 'steelPipes'
  | 'steelPipesWithPlumbing'
  | 'electricalFaults'
  | 'lighting'
  | 'pitchFibreDrains';

export type PolicyExclusionItemType = InternalPolicyExclusionItemType | EvChargerExclusionItemType;

export interface PolicyExclusionItem {
  id: PolicyExclusionItemType;
  info: string;
}

export interface PlanCost {
  withExcess: number;
  noExcess: number;
}

export interface BasePlan {
  coverType: CoverType;
  ipidV3: string;
  terms: string;
  disclosure: string;
  summary: SummaryItemType[];
  policyExclusion: PolicyExclusionItemType[];
}

export interface ProductCost {
  initial: number;
  current: number;
}

export interface ProductDiscount {
  amount: number;
  percentage: number;
}

export interface ProductPromoDiscount extends ProductDiscount {
  code: string;
  lengthInMonths?: number;
  endDate?: string;
}

export interface ProductBase {
  cost: ProductCost;
  discount?: ProductDiscount;
  promoDiscount?: ProductPromoDiscount;
}

export interface ProductInstance extends ProductBase {
  status: ProductStatus;
  activeFrom?: string;
  activeUntil?: string;
}

export interface BundleInstance extends ProductInstance {
  boltons: ProductInstance[];
}

export interface ActivatedProducts {
  boltons: ProductInstance[];
  bundle?: BundleInstance;
}

export type FuelType = 'Electricity' | 'Gas';

type FixedContractProperties = {
  type: 'fixed';
  expiryDate: number;
};

type VariableContractProperties = {
  type: 'variable' | 'half-hourly';
  expiryDate: number | null;
};

type HHContractProperties = {
  type: 'half-hourly';
  expiryDate: number | null;
};

export type ClockingTimeLabel = 'anytime' | 'day' | 'night' | 'heating' | 'evening and weekend';
export type TimeOfUseLabel = 'anytime' | 'peak' | 'offpeak' | 'heating' | 'evening and weekend';

export type OrexEnergyContract = {
  contractId: string;
  mpxn: string;
  startDate: number;
  fuel: FuelType;
  standingRate: number;
  exitFee: number;
  planName: string;
  serviceAccountId: string;
  lengthInMonths: number | null;
  region: string;
  paymentMethod: string;
  familyId: string;
  meterType: string;
  msn: string;
  lossDate: null | string;
  profileClass: string;
  status: {
    active: boolean;
    inRenewal: boolean;
    inLoss: boolean;
    lossComplete: boolean;
    hasFutureContracts: boolean;
  };
  unitRates: Array<{
    unitRate: number;
    clockingTimeLabel: ClockingTimeLabel;
    timeOfUseLabel: TimeOfUseLabel;
  }>;
} & (FixedContractProperties | VariableContractProperties | HHContractProperties);

export type OrexEnergyContracts = {
  electricity: Array<OrexEnergyContract>;
  gas: Array<OrexEnergyContract>;
  hasOnlineDiscount: boolean;
};

interface OptimizelyEvent {
  type: 'event' | 'page' | 'user';
  eventName?: string;
  tags?: OptimizelyTags;
  pageName?: string;
  attributes?: OptimizelyTags;
}

interface Optimizely {
  get: (type: string) => {
    getExperimentStates: (state: { isActive: boolean }) => {
      [id: string]: OptimizelyXExperimentState;
    };
  };

  push(event: OptimizelyEvent): void;
}

export interface OptimizelyTags {
  [key: string]: string;
}

export interface OptimizelyXExperimentState {
  experimentName: string;
  variation: {
    id: string;
    name: string;
  };
}

declare global {
  interface Window {
    optimizely?: Optimizely;
    Trustpilot: {
      loadFromElement: (element: HTMLElement) => void;
    };
  }
}

export type PromoConfig = {
  name: PromoName;
  order: number;
  startDate?: Date;
  endDate?: Date;
  appliesTo: ProductName[];
  promoOptions: allPromoOptions;
  validate?: () => boolean;
  addon?: boolean;
  footnote?: Footnote;
};

export type IndirectOffer = 'BlackFriday' | '4MonthsFree' | 'Energy Credit';

export type DirectOffer = 'GiftCard' | 'OCEAN1';

export type Promo =
  | 'FIRSTMONTHSFREE'
  | 'FIRSTMONTHSDISCOUNT'
  | 'PERCENTDISCOUNT'
  | 'GIFTCARD'
  | 'ENERGYCREDIT'
  | 'FIXEDDISCOUNT'
  | 'FIXEDTERMFIXEDPRICE'
  | 'FREE'
  | 'FREEREPAIR';

export type CombinedPromo = 'FIRSTMONTHSDISCOUNTENERGYCREDIT';

export enum PromoName {
  Q1Offer = 'Q1Offer',
  EnergyCreditSVT = 'EnergyCreditSVT',
  EnergyCreditFixed = 'EnergyCreditFixed',
  GiftCard = 'GiftCard',
  Q2AbsOffer = 'Q2AbsOffer',
  Q2AbsOfferAutoTest = 'Q2AbsOfferAutoTest',
  Q1AbsDirectOffer = 'Q1AbsDirectOffer',
  Q1AbsDirectOfferAutoTest = 'Q1AbsDirectOfferAutoTest',
  FirstMonthsFreeTest = 'FirstMonthsFreeTest',
  AbsBeyond = 'AbsBeyond',
  BeyondFreeAbs = 'BeyondFreeAbs',
  BeyondFreeAbsAutoTest = 'BeyondFreeAbsAutoTest',
  Beyond12MonthsHalfPrice = 'Beyond12MonthsHalfPrice',
  Beyond12MonthsHalfPriceAutoTest = 'Beyond12MonthsHalfPriceAutoTest',
  EnergyRefixFreeABS = 'EnergyRefixFreeABS',
  OSPFreeABS = 'OSPFreeABS',
  Q43MonthsFree = 'Q43MonthsFree',
  HeadlineOfferThreeMonthsFree = 'HeadlineOfferThreeMonthsFree',
  TargetedOfferFourMonthsFree = 'TargetedOfferFourMonthsFree',
  TargetedOfferFourMonthsFreeAutoTest = 'TargetedOfferFourMonthsFreeAutoTest',
  TargetedOfferSixMonthsFree = 'TargetedOfferSixMonthsFree',
  FlashSaleSixMonthsFree = 'FlashSaleSixMonthsFree',
  FlashSaleSixMonthsFreeAutoTest = 'FlashSaleSixMonthsFreeAutoTest',
  BlackFridaySixMonthsFree = 'BlackFridaySixMonthsFree',
  HomeRecoverFreeRepair = 'HomeRecoverFreeRepair',
  HomeRecoverFreeRepairAutoTest = 'HomeRecoverFreeRepairAutoTest',
  AbsThreeYearOnePoundOffer = 'AbsThreeYearOnePoundOffer',
  Q120256MonthsHalfPrice = 'Q120256MonthsHalfPrice',
  Q120258MonthsHalfPrice = 'Q120258MonthsHalfPrice',
  Q1202512MonthsHalfPrice = 'Q1202512MonthsHalfPrice',
  EvChargerLaunchFiftyPercentOff = 'EvChargerLaunchFiftyPercentOff',
  EvChargerLaunchFree = 'EvChargerLaunchFree',
}

type basePromoOptions = {
  type: Promo;
  codes?: string[];
};

type TieredAmountOptions = {
  amounts: {
    [key in ProductName]?: number;
  };
} & basePromoOptions;

type FreePromoOptions = {
  type: 'FREE';
} & basePromoOptions;

type FreeRepairOptions = {
  type: 'FREEREPAIR';
} & basePromoOptions;

type FirstMonthsPromoOptions = {
  type: 'FIRSTMONTHSFREE';
  duration: number;
} & basePromoOptions;

type FirstMonthsDiscountPromoOptions = {
  type: 'FIRSTMONTHSDISCOUNT';
  duration: number;
  discountPercentage: number;
} & basePromoOptions;

type PercentDiscountPromoOptions = {
  type: 'PERCENTDISCOUNT';
  percentage: number;
} & basePromoOptions;

type PromoCodeFixedDiscountOptions = {
  type: 'FIXEDDISCOUNT';
  amount: number;
} & basePromoOptions;

type GiftCardPromoOptions = {
  type: 'GIFTCARD';
} & TieredAmountOptions &
  basePromoOptions;

type EnergyCreditPromoOptions = {
  type: 'ENERGYCREDIT';
} & TieredAmountOptions;

export type FixedTermFixedPricePromoOptions = {
  type: 'FIXEDTERMFIXEDPRICE';
  duration: number;
  value: number;
} & basePromoOptions;

type basePromo = {
  type: Promo;
  value: number;
  name: keyof typeof PromoName;
  startDate?: Date;
  endDate?: Date;
  order: number;
  footnote?: Footnote;
};

export type FixedDiscountPromo = {
  type: 'FIXEDDISCOUNT';
} & basePromo;

export type PercentDiscountPromo = {
  type: 'PERCENTDISCOUNT';
} & basePromo;

export type EnergyCreditPromo = {
  type: 'ENERGYCREDIT';
} & basePromo;

export type GiftCardPromo = {
  type: 'GIFTCARD';
} & basePromo;

export type FirstMonthsPromo = {
  type: 'FIRSTMONTHSFREE';
  duration: number;
} & basePromo;

export type FreePromo = {
  type: 'FREE';
} & basePromo;

export type FreeRepair = {
  type: 'FREEREPAIR';
} & basePromo;

export type FirstMonthsDiscountPromo = {
  type: 'FIRSTMONTHSDISCOUNT';
  duration: number;
} & basePromo;

export type FixedTermFixedPricePromo = {
  type: 'FIXEDTERMFIXEDPRICE';
  duration: number;
} & basePromo;

export type allPromos =
  | FixedDiscountPromo
  | PercentDiscountPromo
  | EnergyCreditPromo
  | GiftCardPromo
  | FirstMonthsPromo
  | FirstMonthsDiscountPromo
  | FixedTermFixedPricePromo
  | FreePromo
  | FreeRepair;

type allPromoOptions =
  | FirstMonthsPromoOptions
  | PercentDiscountPromoOptions
  | GiftCardPromoOptions
  | EnergyCreditPromoOptions
  | PromoCodeFixedDiscountOptions
  | FirstMonthsDiscountPromoOptions
  | FixedTermFixedPricePromoOptions
  | FreePromoOptions
  | FreeRepairOptions;

export type Quote = {
  name: string;
  price: number;
  originalPrice?: number;
  excess: number;
  endDate?: Date;
  startDate?: Date;
  headline: boolean;
  productName: ProductName;
  displayName?: string;
  firstMonthsPrice?: number;
  promotions: allPromos[];
  discountApplied?: boolean;
  olderBoilerPremium?: number;
  typeOfCover?: string;
} & BasePlan;

export type AccountId = string | undefined;
