import { PageProps } from 'gatsby';

import { PaymentDetailsPage } from '../PaymentDetails';

import { useCustomMixpanel } from '@services/Mixpanel';

const EvChargerPaymentPage = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();

  return <PaymentDetailsPage {...props} mixpanel={mixpanel} />;
};

export default EvChargerPaymentPage;
