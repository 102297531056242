import { PageProps } from 'gatsby';

import useEvCharger from '@hooks/useEvCharger';
import { useCustomMixpanel } from '@services/Mixpanel';
import { ConfirmationPage } from '@src/modules';

const EvChargeConfirmation = (props: PageProps) => {
  useEvCharger();
  const mixpanel = useCustomMixpanel();
  return <ConfirmationPage {...props} mixpanel={mixpanel} />;
};

export default EvChargeConfirmation;
