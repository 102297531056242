import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { useMemo, useRef, useState } from 'react';
import { Heading2, P } from '@ovotech/element';

import { CarouselNavigation } from './CarouselNavigation';
import { DiscoverMoreCard } from './DiscoverMoreCard';

import { mqMediumUp } from '@utils/mediaQuery';
import { useMediaIs } from '@hooks/useMediaIs';
import { useCustomMixpanel } from '@services/Mixpanel';
import { MixpanelEvents } from '@entities/enums';

const Root = styled(P)`
  display: flex;
  flex-direction: column;
  z-index: 1;
  ${mqMediumUp(`
    display: grid;
    justify-self: center;
    grid-template-columns: 1fr minmax(500px, 1fr);
  `)};
`;

const Content = styled.div`
  ${mqMediumUp(`
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
 `)};
`;

const Container = styled(SwiperSlide)`
  overflow: auto;
  .swiper-slide {
    width: fit-content;
    height: auto;
    display: flex;
  }
`;

const Wrapper = styled.div`
  padding: 48px 16px 0;
  ${mqMediumUp(`
    padding-top: 84px;
  `)};
`;

const Heading = styled(Heading2)`
  ${({ theme }) => `
    padding: 0 16px;
    color: ${theme.core.color.brand.white};
    font-size: 48px;
    line-height: 48px;
    ${mqMediumUp(`
      font-size: 64px;
      line-height: 60px;
      padding: 144px 16px 0;
    `)};
  `}
`;

const Paragraph = styled(P)`
  ${({ theme }) => `
    padding: 24px 16px 48px;
    color: ${theme.core.color.brand.white};
    font-size: 24px;
    line-height: 28px;
    ${mqMediumUp(`
      line-height: 28px;
      padding: 16px 16px 0;
    `)};
  `}
`;

type Card = {
  imageSrc: string;
  title: string;
  description: string;
};

type SwiperState = {
  isBeginning: boolean;
  isEnd: boolean;
  allowSlidePrev: boolean;
  allowSlideNext: boolean;
};

type DiscoverMoreCarouselProps = {
  cards: Card[];
};

export const DiscoverMoreCarousel = ({ cards }: DiscoverMoreCarouselProps) => {
  const { track } = useCustomMixpanel();
  const swiperRef = useRef<SwiperClass | undefined>();
  const [swiperState, setSwiperState] = useState<SwiperState>({
    isBeginning: false,
    isEnd: false,
    allowSlidePrev: false,
    allowSlideNext: false,
  });
  const isMobile = useMediaIs('mobile', 'max');

  const handleStateChange = (swiper: SwiperClass) => {
    swiperRef.current = swiper;
    const { isBeginning, isEnd, allowSlidePrev, allowSlideNext } = swiper;
    setSwiperState({
      isBeginning,
      isEnd,
      allowSlidePrev,
      allowSlideNext,
    });
  };

  const navigation = useMemo(() => {
    const handlePrev = () => {
      track(MixpanelEvents.CAROUSEL_NAVIGATION_BUTTON_CLICK, {
        carouselId: 'discoverMore',
        buttonId: 'previous',
      });
      swiperRef.current?.slidePrev();
    };
    const handleNext = () => {
      track(MixpanelEvents.CAROUSEL_NAVIGATION_BUTTON_CLICK, {
        carouselId: 'discoverMore',
        buttonId: 'next',
      });
      swiperRef.current?.slideNext();
    };
    return (
      <Wrapper>
        <CarouselNavigation
          allowSlideNext={swiperState.allowSlideNext}
          allowSlidePrev={swiperState.allowSlidePrev}
          isBeginning={swiperState.isBeginning}
          isEnd={swiperState.isEnd}
          onNext={handleNext}
          onPrev={handlePrev}
        />
      </Wrapper>
    );
  }, [swiperState]);

  const handleTouchMove = () => {
    if (swiperRef.current?.touches.diff) {
      track(MixpanelEvents.CAROUSEL_TOUCH_SWIPE, {
        carouselId: 'discoverMore',
        touchDirection: swiperRef.current?.touches.diff < 0 ? 'forward' : 'backward',
      });
    }
  };

  return (
    <Root>
      <Content>
        <Heading>Discover more</Heading>
        <Paragraph>Unlock the full potential of your cover.</Paragraph>
        {!isMobile && navigation}
      </Content>

      <Container>
        <Swiper
          slidesPerView="auto"
          spaceBetween={16}
          slidesOffsetAfter={16}
          slidesOffsetBefore={16}
          onSwiper={handleStateChange}
          onSlideChange={handleStateChange}
          onReachEnd={handleStateChange}
          onReachBeginning={handleStateChange}
          onUpdate={handleStateChange}
          onResize={handleStateChange}
          onTouchEnd={handleTouchMove}
          speed={600}
        >
          {cards.map(({ imageSrc, title, description }) => (
            <SwiperSlide key={title}>
              {({ isActive }) => (
                <DiscoverMoreCard
                  imageSrc={imageSrc}
                  title={title}
                  description={description}
                  isActive={isActive}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>

      {isMobile && navigation}
    </Root>
  );
};
