import { PageProps } from 'gatsby';
import React from 'react';
import { useDecision } from '@optimizely/react-sdk';

import { ModalContextProvider } from '@contexts/ModalContext';
import { HomeRecoverOverview } from '@src/modules/HomeRecoverOverview/HomeRecoverOverview';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { QuotesPlatformHomeRecoverOverview } from '@src/modules/HomeRecoverOverview/QuotesPlatformHomeRecoverOverview';
import ABTest from '@components/ABTest';
import { useAuth } from '@hooks/useAuth';
import { HomeRecoverVariation } from '@src/modules/HomeRecoverVariation/HomeRecoverVariation';

export const HomeRecover = (props: PageProps) => {
  const [{ enabled: isQuotesPlatform }] = useDecision(OptimizelyFeatureFlag.QUOTE_PLATFORM_ROLLOUT);
  const { user } = useAuth();

  if (isQuotesPlatform) {
    return <QuotesPlatformHomeRecoverOverview />;
  }

  return (
    <ModalContextProvider>
      <ABTest
        experimentName={OptimizelyFeatureFlag.HOME_RECOVER_LANDING_PAGE}
        accountId={user?.accountId}
      >
        {(flagEnabled, variationKey) => {
          const isVariation = flagEnabled && variationKey === 'variation';

          return isVariation ? (
            <HomeRecoverVariation {...props} />
          ) : (
            <HomeRecoverOverview {...props} />
          );
        }}
      </ABTest>
    </ModalContextProvider>
  );
};

export default HomeRecover;
