import { Li, P, Strong, Tab, TabList, TabPanel, Tabs, TextLink, Ul } from '@ovotech/element';
import styled from 'styled-components';

import { ModalConfig } from '@contexts/ModalContext/types';
import { BulletWithIcon } from '@components/BulletWithIcon';
import HomeRecoverTerms from '@src/resources/home-recover-terms.pdf';
import CoverTerms from '@src/resources/terms-v3.pdf';
const IncludedLI = styled(Li)`
  margin-left: 15px;
`;
export const getRepairModalConfig = (): ModalConfig => {
  return {
    id: 'repair-whats-included',
    title: 'Whats included after your first repair',
    details: [
      <>
        <TabPanel>
          <Tabs>
            <TabList>
              <Tab>Included</Tab>
              <Tab>Not Included</Tab>
            </TabList>
            <TabPanel>
              <BulletWithIcon
                flexStart
                icon={{ name: 'energy-advice', size: 24 }}
                text={<Strong>Expert customer support</Strong>}
              />
              <Ul>
                <IncludedLI>A 24/7 priority helpline</IncludedLI>
                <IncludedLI>Unlimited callouts</IncludedLI>
              </Ul>
              <BulletWithIcon
                icon={{ name: 'flame', size: 24 }}
                text={<Strong>Gas central heating system</Strong>}
              />
              <Ul>
                <IncludedLI>Boilers</IncludedLI>
                <IncludedLI>Controls</IncludedLI>
                <IncludedLI>Clocks and programmers</IncludedLI>
                <IncludedLI>Thermostats</IncludedLI>
                <IncludedLI>Radiators</IncludedLI>
                <IncludedLI>Water tanks</IncludedLI>
              </Ul>
              <BulletWithIcon
                flexStart
                icon={{ name: 'boiler-service', size: 24 }}
                text={<Strong>Boiler care</Strong>}
              />
              <Ul>
                <IncludedLI>
                  Annual boiler service to keep things running safely and efficiently
                </IncludedLI>
                <IncludedLI>Boiler replacement cover, subject to terms and conditions</IncludedLI>
              </Ul>
              <BulletWithIcon
                flexStart
                icon={{ name: 'electric', size: 24 }}
                text={<Strong>Electrics and lighting</Strong>}
              />
              <Ul>
                <IncludedLI>Fuse boxes</IncludedLI>
                <IncludedLI>Circuit breakers</IncludedLI>
                <IncludedLI>Sockets</IncludedLI>
                <IncludedLI>Light fittings</IncludedLI>
                <IncludedLI>Dimmers, downlights, and standard fluorescent tubes</IncludedLI>
              </Ul>
              <BulletWithIcon
                flexStart
                icon={{ name: 'plumbing', size: 24 }}
                text={<Strong>Drains and plumbing</Strong>}
              />
              <Ul>
                <IncludedLI>Home drains</IncludedLI>
                <IncludedLI>Waste pipes</IncludedLI>
                <IncludedLI>Leaking taps</IncludedLI>
                <IncludedLI>Running toilets</IncludedLI>
              </Ul>
            </TabPanel>
            <TabPanel>
              <>
                <Ul>
                  <Li>Accidental damage</Li>
                  <Li>Claims within 30 days after initial repair</Li>
                  <Li>Boilers beyond economical repair</Li>
                  <Li>Damage caused by corrosion or sludge</Li>
                  <Li>Faults caused by system design or faulty installation</Li>
                  <Li>
                    Faults caused because something unsuitable has been thrown away in a drain,
                    public drain or sewer
                  </Li>
                  <Li>Cylinders, extended flue systems and steel or iron pipes</Li>
                  <Li>Replacement taps, shower hardware, and leaking toilet pans</Li>
                </Ul>
                <P>
                  For more details about what is and isn’t included in the Home Recover plan, take a
                  look at the{' '}
                  <TextLink href={HomeRecoverTerms} target="_blank" rel="noreferrer">
                    first repair terms and conditions
                  </TextLink>{' '}
                  and the{' '}
                  <TextLink href={CoverTerms} target="_blank">
                    cover terms and conditions.
                  </TextLink>
                </P>
              </>
            </TabPanel>
          </Tabs>
        </TabPanel>
      </>,
    ],
  };
};
