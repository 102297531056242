import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { getFirstError } from '@utils/getFirstError';

export const ScrollToFieldError = <T,>({
  fieldsInOrder,
}: {
  fieldsInOrder: (keyof T)[];
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid || submitCount === 0) return;

    const element = document.getElementById(
      `${String(getFirstError<T>(fieldsInOrder, errors))}`
    );

    if (!element?.scrollIntoView) return;

    element.scrollIntoView({ block: 'center' });
  }, [submitCount, isValid]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
