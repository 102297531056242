import { Card, Heading4, Margin } from '@ovotech/element';
import styled from 'styled-components';

import { BulletWithIcon } from '@components/BulletWithIcon';

const RoundedCard = styled(Card)`
  position: relative;
  border-radius: 22px;
  ${({ theme }) => `
    padding-inline: ${theme.core.space[6]};
    padding-top: ${theme.core.space[8]};
  `};

  min-width: 268px;
`;

const BlackHeading4 = styled(Heading4)`
  ${({ theme }) => `
    color: ${theme.core.color.brand.black};
  `};
`;

const BulletPointContainer = styled.div`
  & > p {
    line-height: 1.2;
  }
`;
export const WhatYouGetCard = ({
  heading,
  bulletPoints,
}: {
  heading: string;
  bulletPoints: string[];
}) => {
  return (
    <RoundedCard>
      <BlackHeading4>{heading}</BlackHeading4>
      <Margin top={8} />
      <BulletPointContainer id="bullet-container">
        {bulletPoints.map((bulletPoint, index) => (
          <BulletWithIcon
            flexStart
            key={index}
            icon={{ name: 'fill-green-tick', size: 16 }}
            text={bulletPoint}
          />
        ))}
      </BulletPointContainer>
    </RoundedCard>
  );
};
