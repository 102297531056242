import { Modal } from '@ovotech/element';

type EligibilityModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const EligibilityModal = ({ isOpen, onClose }: EligibilityModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      title="You’re eligible for EV Charger Cover if:"
      isDismissable={true}
      onClose={onClose}
    >
      <li>you own the EV charger.</li>
      <li>your charger is not used to generate income.</li>
      <li>your charger does not have an output over 22 kW.</li>
      <li>you live in England, Wales, Scotland or the Isle of Wight.</li>
    </Modal>
  );
};
