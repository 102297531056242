import { PageProps } from 'gatsby';

import useEvCharger from '@hooks/useEvCharger';
import EvChargerPersonalDetailsPage from '@src/modules/EvCharger/EvChargerPersonalDetailsPage';

const EvChargerPersonalDetails = (props: PageProps) => {
  useEvCharger();
  return <EvChargerPersonalDetailsPage {...props} />;
};

export default EvChargerPersonalDetails;
