import { BasePlan } from '@entities/types';
import disclosure from '@resources/disclosure-v3.pdf';

export const EV_CHARGER_TERMS =
  'https://www.ovobyus.com/m/18f35a4c921d9500/original/HS-5561-EV-Charger-Insurance-T-Cs-1_v16.pdf';
export const planEvCharger: Omit<BasePlan, 'coverType'> = {
  ipidV3:
    'https://www.ovobyus.com/m/3b488782c62f78a5/original/HS-6721-EV-Charger-Cover-IPID_2_v7.pdf',
  terms: EV_CHARGER_TERMS,
  disclosure,
  summary: [],
  policyExclusion: [
    'evChargerFirstMonth',
    'evChargerExistingFaults',
    'evChargerVehichleToHomeVehichleToGrid',
    'evChargerIncompatibleReplacementParts',
    'evChargerNonDomesticCharger',
    'evChargerFloodDamage',
    'evChargerDamageToProperty',
    'evChargerLossOfEarnings',
    'evChargerConsumerUnits',
    'evChargerCosmeticDamage',
    'evChargerAttachedToCommunalAreas',
    'evChargerNotMaintainedByStandards',
    'evChargerCostOfWiringOrRedecoration',
    'evChargerWifiOrApps',
  ],
};

export default planEvCharger;
