import styled from 'styled-components';
import { useDecision } from '@optimizely/react-sdk';
import { useAtomValue } from 'jotai';

import { InfoIcon } from '@components/Info';
import { useModalContext } from '@contexts/ModalContext';
import { SummaryItem } from '@entities/types';
import { stripBullet } from '@fixtures/summaryItems';
import { MixpanelEvents } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';
import { excessAtom, isAbsAtom, is95ExcessAtom, isHomeRecoverAtom } from '@src/store/store';

interface Props {
  item: SummaryItem;
  iconName?: string;
}

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  display: inline-flex;
`;

export const FindOutMore = ({ item, iconName = 'info-dark' }: Props) => {
  const { showModal } = useModalContext();
  const excess = useAtomValue(excessAtom);
  const is95Excess = useAtomValue(is95ExcessAtom);
  const isHomeRecover = useAtomValue(isHomeRecoverAtom);
  const isAbs = useAtomValue(isAbsAtom);

  const { track } = useCustomMixpanel();

  const [detailedPlanSelectionDecision] = useDecision('detailed-plan-selection');

  const displayDetailedPlanSelection =
    !isAbs &&
    !isHomeRecover &&
    !is95Excess &&
    detailedPlanSelectionDecision.enabled &&
    detailedPlanSelectionDecision.variationKey === 'brainlabs';

  return (
    <StyledInfoIcon
      name={iconName}
      size={16}
      data-testid={`find-out-more-${item.id}`}
      onClick={() => {
        showModal({
          id: item.id,
          title: item.name,
          details: item.info[excess ? 'withExcess' : 'noExcess'].map(stripBullet),
        });
        displayDetailedPlanSelection &&
          track(MixpanelEvents.BUTTON_CLICK, {
            buttonId: `detailedPlanSelectionInfoIcon-${item.name}`,
          });
      }}
    />
  );
};
