import { Heading2, TextLink } from '@ovotech/element';
import styled from 'styled-components';
import { useState } from 'react';

import { CoverCard } from './CoverCard';
import { WhatsIncludedEvChargerModal } from './WhatsIncludedEvChargerModal';
import { ChooseYourExcessModal } from './ChooseYourExcessModal';

import { mqMediumUp } from '@utils/mediaQuery';
import useEvCharger from '@hooks/useEvCharger';
import { usePromoComponents } from '@hooks/usePromoComponents';

const Section = styled.div`
  padding: 88px 16px;
  background: ${({ theme }) => theme.core.color.brand.ovo};
  ${mqMediumUp(`
    padding: 144px 16px 176px;
  `)};
`;

const Heading = styled(Heading2)`
  font-size: 48px;
  line-height: 48px;
  color: white;
  text-align: center;
  ${mqMediumUp(`
    font-size: 64px;
    line-height: 64px;
    text-align: center;
  `)};
`;

const Message = styled.div`
  padding: 20px;
  background: white;
  border-radius: 22px;
  max-width: 330px;
  margin-top: 32px;
  text-align: center;
  ${mqMediumUp(`
    padding: 12px;
    margin-top: 44px;
    text-align: center;
    max-width: 100%;
  `)};
`;

const Link = styled(TextLink)`
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  ${mqMediumUp(`
    flex-direction: row;
    justify-content: center;
  `)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  margin: 40px auto 0;
  ${mqMediumUp(`
    margin: 80px auto 0;
  `)};
`;

export const ChooseYourCoverSection = () => {
  const { quotes } = useEvCharger();
  const [isCoverModalOpen, setIsCoverModalOpen] = useState(false);
  const [isExcessModalOpen, setIsExcessModalOpen] = useState(false);
  const { getEvChargerChooseYourCover } = usePromoComponents();

  const handleExcessModalOpen = () => {
    setIsExcessModalOpen(true);
  };

  const handleExcessModalClose = () => {
    setIsExcessModalOpen(false);
  };

  const handleCoverModalOpen = () => {
    setIsCoverModalOpen(true);
  };

  const handleCoverModalClose = () => {
    setIsCoverModalOpen(false);
  };

  const evChargerNoExessQuote = quotes.find((quote) => quote.excess === 0);

  return (
    <Section id="choose-your-cover">
      {!evChargerNoExessQuote?.discountApplied && <Heading>Choose your cover</Heading>}
      {evChargerNoExessQuote?.discountApplied &&
        evChargerNoExessQuote?.promotions.map((promo) => getEvChargerChooseYourCover(promo))}
      <Container>
        <Cards>
          {quotes
            .sort(
              (quoteA, quoteB) =>
                (quoteA?.originalPrice || quoteA.price) - (quoteB?.originalPrice || quoteB.price)
            )
            .map((quote) => {
              return (
                <CoverCard
                  key={quote.name}
                  quote={quote}
                  onExcessInfoClick={handleExcessModalOpen}
                />
              );
            })}
        </Cards>

        <Message>
          <Link onClick={handleCoverModalOpen}>Check everything included in EV Charger Cover.</Link>
        </Message>
      </Container>

      <ChooseYourExcessModal isOpen={isExcessModalOpen} onClose={handleExcessModalClose} />
      <WhatsIncludedEvChargerModal isOpen={isCoverModalOpen} onClose={handleCoverModalClose} />
    </Section>
  );
};
