import { PageProps } from 'gatsby';

import { useCustomMixpanel } from '@services/Mixpanel';
import { PersonalDetailsPage } from '@src/modules/PersonalDetails/PersonalDetailsPage';

const EvChargerPersonalDetailsPage = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();
  return <PersonalDetailsPage {...props} mixpanel={mixpanel} />;
};

export default EvChargerPersonalDetailsPage;
