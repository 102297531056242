import styled from 'styled-components';
import { Heading1, Heading4, Icon, CTAButton, theme } from '@ovotech/element';

import { InnerContainer } from './ styled';

import HomeRecoverDesktop from '@resources/images/homeRecoverDesktop.png';
import HomeRecoverMobile from '@resources/images/homeRecoverMobile.png';
import { mqMediumDown, mqSmallDown, mqWideMediumDown } from '@utils/mediaQuery';

type StyledCoverImage = {
  backgroundImage: string;
};

const BannerContainer = styled.div`
  ${({ theme }) => `
      padding-top: ${theme.core.space[10]};
  `}
`;

const TopHeroSection = styled(InnerContainer)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  align-items: center;
  margin-bottom: 132px;
  gap: 6%;
  ${mqMediumDown(`
    grid-template-columns: 1fr; 
    gap: 0;
    margin-bottom: 0px;
  `)};
`;

const TopHeroInfoSection = styled.div`
  ${({ theme }) => `
      padding-top: ${theme.core.space[10]};
      
      ${mqMediumDown(`padding: 0 12px ${theme.core.space[13]} 12px;`)};
  `}
`;

const StyledHeading4 = styled(Heading4)`
  ${({ theme }) => `
      margin-bottom: ${theme.core.space[3]};
      font-size: 32px;
      
      ${mqMediumDown(`font-size: 24px; margin-bottom: ${theme.core.space[3]};`)};
  `}
`;

const StyledHeading1 = styled(Heading1)`
  font-size: 80px;
  line-height: 1;
  letter-spacing: -3px;
  width: 500px;
  max-width: 100%;

  ${mqWideMediumDown(`font-size: 62px; width: auto;`)};
`;

const StyledCTAButton = styled(CTAButton)`
  ${({ theme }) => `
      padding: ${theme.core.space[2]} 82px};
      margin-top: ${theme.core.space[12]};
      max-width: 100%:
  `}
`;

const BottomHeroSectionWide = styled.div`
  ${({ theme }) => `
      background-color: ${theme.core.color.brand.white};
  `}
`;

const BottomHeroSection = styled(InnerContainer)`
  ${({ theme }) => `
      background-color: ${theme.core.color.brand.white};
      padding: ${theme.core.space[10]} 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-content: center;
      align-items: center;
  ${mqMediumDown(`
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  `)};
 `}
`;

const BottomHeroItem = styled.div`
  ${({ theme }) => `
      padding: ${theme.core.space[2]} ${theme.core.space[8]} 0;
      margin: 0 ${theme.core.space[2]};
      display: flex;
      justify-content: left;
      align-items: center;
      // min-width: 334px;
      
      ${mqMediumDown(
        `justify-content: start; padding: ${theme.core.space[6]} ${theme.core.space[4]}`
      )};
      
      &:nth-child(n + 2) {
        border-left: 1px solid #ECE7E3;
        
        ${mqMediumDown(`border-left: none; border-top: 1px solid #ECE7E3;`)};
      }
  `}
`;

const BottomHeroInfo = styled.div`
  ${({ theme }) => `
      margin-left: ${theme.core.space[3]};
  `}
`;

const BottomHeroTitle = styled.div`
  ${({ theme }) => `
      font-size: 32px;
      font-weight: 900;
      line-height: 1;
      letter-spacing: -1.28px;
      font-family: ${theme.core.fontFamily.heading};
      
      ${mqMediumDown(`font-size: 48px;`)};
  `}
`;

const BottomHeroDescription = styled.div`
  ${({ theme }) => `
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
      font-family: ${theme.core.fontFamily.heading};
  `}
`;

const TopHeroCoverImageDesktop = styled.div<StyledCoverImage>`
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(${({ backgroundImage }) => backgroundImage});

  ${mqMediumDown(`display: none`)};
`;

const TopHeroCoverImageMobile = styled.div<StyledCoverImage>`
  display: none;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(${({ backgroundImage }) => backgroundImage});

  ${mqMediumDown(`display: block; height: 300px;`)};
  ${mqSmallDown(`height: 219px;`)};
`;

type HeroBannerProps = {
  onScrollToPlan: () => void;
};

export const HeroBanner = ({ onScrollToPlan }: HeroBannerProps) => {
  return (
    <BannerContainer>
      <TopHeroSection>
        <TopHeroInfoSection>
          <StyledHeading4>Home Recover</StyledHeading4>
          <StyledHeading1>Fix your home emergency now, stay covered later.</StyledHeading1>
          <StyledCTAButton onClick={onScrollToPlan}>Book emergency repair</StyledCTAButton>
        </TopHeroInfoSection>
        <TopHeroCoverImageDesktop backgroundImage={HomeRecoverDesktop} />
        <TopHeroCoverImageMobile backgroundImage={HomeRecoverMobile} />
      </TopHeroSection>
      <BottomHeroSectionWide>
        <BottomHeroSection>
          <BottomHeroItem>
            <Icon name="star" size={32} />
            <BottomHeroInfo>
              <BottomHeroTitle>4.5</BottomHeroTitle>
              <BottomHeroDescription>TrustPilot rating</BottomHeroDescription>
            </BottomHeroInfo>
          </BottomHeroItem>
          <BottomHeroItem>
            <Icon name="boilerEngineerGreen" size={32} />
            <BottomHeroInfo>
              <BottomHeroTitle>120,000+</BottomHeroTitle>
              <BottomHeroDescription>Repairs last year</BottomHeroDescription>
            </BottomHeroInfo>
          </BottomHeroItem>
          <BottomHeroItem>
            <Icon name="home" size={32} color={theme.core.color.brand.leaf} />
            <BottomHeroInfo>
              <BottomHeroTitle>250,000+</BottomHeroTitle>
              <BottomHeroDescription>Homes covered</BottomHeroDescription>
            </BottomHeroInfo>
          </BottomHeroItem>
        </BottomHeroSection>
      </BottomHeroSectionWide>
    </BannerContainer>
  );
};
