import styled from 'styled-components';
import { Heading1, Heading4, Lead, Margin, Modal, Strong } from '@ovotech/element';

import { InnerContainer as BaseInnerContainer } from '../ styled';

import { WhatYouGetCard } from './WhatYouGetCard';
import { whatYouGetCardContent } from './constants';
import { getRepairModalConfig } from './WhatsIncludedRepairContent';

import SquareSectionNumber from '@components/SquareSectionNumber';
import CouchImage from '@resources/images/couch.png';
import { BulletWithIcon } from '@components/BulletWithIcon';
import { useModalContext } from '@contexts/ModalContext';
import { mqDesktopDown, mqLargeUp, mqMediumDown } from '@utils/mediaQuery';

const Container = styled.div`
  padding-block: 88px;
  display: flex;
  flex-direction: column;

  background: linear-gradient(159deg, #0a9828 22.81%, #0a9828 56.85%, #16b639 86.56%);
  ${({ theme }) => `
    background-color: ${theme.core.color.brand.ovo};
  `};
`;

const HeroText = styled(Heading1)`
  ${({ theme }) => `
    color: ${theme.core.color.brand.white};
    font-size: 48px;
    line-height:1;

    ${mqLargeUp(`
      font-size: 64px;
    `)}
  `};
`;

const WhiteHeading1 = styled(Heading1)`
  ${({ theme }) => `
    color: ${theme.core.color.brand.white};
    font-size: 48px;
    line-height:48px;
  `};
`;

const HeadingContainer = styled.div`
  ${({ theme }) => `
    color: ${theme.core.color.brand.white};
  `};
`;

const WhiteHeading4 = styled(Heading4)`
  ${({ theme }) => `
        color: ${theme.core.color.brand.white};
        margin: 0 0 ${theme.core.space[2]};
    `};
`;

const WhiteLead = styled(Lead)`
  ${({ theme }) => `
        color: ${theme.core.color.brand.white};
    `};
`;

const CouchImageContainer = styled.div`
  ${mqLargeUp(`
  background-image: url(${CouchImage});
  background-repeat: no-repeat;
  background-position: top;
  
  `)}
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2%;
  width: 80%;
  margin: 0 auto;
  padding: 56px 0;
  max-width: 1200px;
  &::-webkit-scrollbar {
    display: none;
  }
  ${mqDesktopDown(`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${mqMediumDown(`
    grid-template-columns: repeat(4, 1fr);
    overflow-x: scroll;
    width: 100%;
    padding-left: 12px;
  `)}
`;

const FindOutWhatsIncludedLink = styled(Lead)`
  ${({ theme }) => `
        color: ${theme.core.color.brand.white};
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        cursor: pointer;


    `};
`;

const Section2Grid = styled.div`
  display: grid;
  min-height: 300px;
  grid-template-columns: 1fr;
  ${mqLargeUp(`
    grid-template-columns: 50% 50%;
  `)}
`;

const InnerContainer = styled(BaseInnerContainer)`
  padding-inline: 12px;
`;

const WhatYouGet = () => {
  const { showModal } = useModalContext();
  return (
    <>
      <Container>
        <InnerContainer>
          <SquareSectionNumber number={1} />
          <HeadingContainer>
            <WhiteHeading4>Book a repair with Home Recover.</WhiteHeading4>
            <HeroText>Get an emergency fix for:</HeroText>
          </HeadingContainer>
        </InnerContainer>
        <CardContainer>
          {whatYouGetCardContent.map((cardContent) => (
            <WhatYouGetCard
              key={cardContent.heading}
              heading={cardContent.heading}
              bulletPoints={cardContent.bulletPoints}
            />
          ))}
        </CardContainer>
        <Margin top={12} bottom={12} />
        <InnerContainer>
          <Section2Grid>
            <div>
              <SquareSectionNumber number={2} />
              <HeadingContainer>
                <WhiteHeading4>After your emergency repair.</WhiteHeading4>
                <WhiteHeading1>Stay protected with ongoing cover.</WhiteHeading1>
              </HeadingContainer>
              <Margin top={12} />
              <FindOutWhatsIncludedLink
                onClick={() => {
                  showModal(getRepairModalConfig());
                }}
              >
                <BulletWithIcon
                  icon={{ name: 'white-fill-tick', size: 24 }}
                  flexStart
                  text={
                    <WhiteLead>
                      <Strong>Find out what’s included in a Home Recover plan</Strong>
                    </WhiteLead>
                  }
                />
              </FindOutWhatsIncludedLink>
            </div>
            <CouchImageContainer></CouchImageContainer>
          </Section2Grid>
        </InnerContainer>
      </Container>
      <Modal></Modal>
    </>
  );
};

export default WhatYouGet;
