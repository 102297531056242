import { PageProps } from 'gatsby';

import useEvCharger from '@hooks/useEvCharger';
import EvChargerPaymentDetailsPage from '@src/modules/EvCharger/EvChargerPaymentDetailsPage';

const EvChargerPaymentDetails = (props: PageProps) => {
  useEvCharger();
  return <EvChargerPaymentDetailsPage {...props} />;
};

export default EvChargerPaymentDetails;
