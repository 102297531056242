import styled from 'styled-components';
import { Heading3, P } from '@ovotech/element';
import { useEffect } from 'react';

import { mqMediumUp } from '@utils/mediaQuery';
import { SupRef } from '@components/Footer';
import { evChargerCoverFireDamageFootnote } from '@constants/footnotes';
import { useCustomMixpanel } from '@services/Mixpanel';
import { MixpanelEvents } from '@entities/enums';

const Container = styled.div`
  ${({ theme }) => `
    width: 290px;
    min-height: 421px;
    background: ${theme.core.color.brand.white};
    border-radius: ${theme.core.radius.large};

    ${mqMediumUp(`
      min-width: 468px;
      height: 624px;
    `)};
  `}
`;

const ImageWrapper = styled.div<{ imageSrc: string }>`
  ${({ theme, imageSrc }) => `
    border-radius: ${theme.core.radius.large} ${theme.core.radius.large} 0 0;
    background-image: url(${imageSrc});
    background-repeat: no-repeat;
    background-size: cover;
    height: 165px;
    width 100%;

    ${mqMediumUp(`
      height: 408px;
    `)};
  `}
`;

const InfoContainer = styled.div`
  ${({ theme }) => `
    padding: ${theme.core.space[6]};

    ${mqMediumUp(`
      padding: ${theme.core.space[10]};
    `)};
  `}
`;

const Title = styled(Heading3)`
  ${({ theme }) => `
    color: ${theme.core.color.brand.black};
    font-size: 28px;
    line-height: 28px;
    ${mqMediumUp(`
      font-size: 32px;
      line-height: 32px;
    `)};
  `}
`;

const StyledP = styled(P)`
  ${({ theme }) => `
    color: ${theme.semantic.message.secondary};
    padding-top: ${theme.core.space[4]};
    line-height: 20px;

    ${mqMediumUp(`
      line-height: 24px;
    `)};
  `}
`;

type DiscoverMoreCardProps = {
  imageSrc: string;
  title: string;
  description: string;
  isActive: boolean;
};

export const DiscoverMoreCard = ({
  imageSrc,
  title,
  description,
  isActive,
}: DiscoverMoreCardProps) => {
  const fireDamageStep = (title: string) => title === 'Cover for damage and fire';
  const { track } = useCustomMixpanel();

  useEffect(() => {
    if (isActive) {
      track(MixpanelEvents.CAROUSEL_SLIDE_ACTIVE, {
        carouselId: 'discoverMore',
        slideId: title,
      });
    }
  }, [isActive]);

  return (
    <Container>
      <ImageWrapper imageSrc={imageSrc} />
      <InfoContainer>
        <Title>{title}</Title>
        <StyledP>
          {description}
          {fireDamageStep(title) && <SupRef footnote={evChargerCoverFireDamageFootnote} />}
        </StyledP>
      </InfoContainer>
    </Container>
  );
};
