import { Icon } from '@ovotech/element';
import styled from 'styled-components';

const Container = styled.div`
  ${({ theme }) => `
      display: grid;
      height: 44px;
      width: 44px;      
      margin: 0 0 ${theme.core.space[8]};
  `}
`;

const SquareIcon = styled(Icon)`
  grid-column: 1;
  grid-row: 1;
`;

const Number = styled.div`
  ${({ theme }) => `
        color: ${theme.core.color.brand.white};
        grid-column: 1;
        grid-row: 1;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        line-height: 44px;
    `}
`;

const SquareSectionNumber = ({ number }: { number: number }) => {
  return (
    <Container>
      <SquareIcon name="square" size={44}></SquareIcon>
      <Number>{number}</Number>
    </Container>
  );
};

export default SquareSectionNumber;
