import { Modal } from '@ovotech/element';
import styled from 'styled-components';

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type ChooseYourExcessModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ChooseYourExcessModal = ({ isOpen, onClose }: ChooseYourExcessModalProps) => {
  return (
    <Modal isOpen={isOpen} title="What is an excess?" isDismissable={true} onClose={onClose}>
      <Stack>
        <div>
          Our customers pay excess to reduce their monthly policy cost. This is what you will pay
          before we send out an engineer to you.
        </div>
        <div>
          If you choose to pay an excess per claim, then you will be charged for each callout. This
          charge does not apply to subsequent visits made within 30 days to fix the fault identified
          at the initial callout.
        </div>
        <div>
          If you choose not to pay an excess per claim, you won’t be charged this payment for each
          callout.
        </div>
      </Stack>
    </Modal>
  );
};
