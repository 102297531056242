import { CTAButton, Icon } from '@ovotech/element';
import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

const Button = styled(CTAButton)`
  ${({ disabled, theme }) => css`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: ${disabled && theme.core.color.brand.energisedTint};
    pointer-events: ${disabled && 'none'};
    svg {
      path {
        fill: ${disabled ? theme.core.color.brand.midnightTint : 'white'};
      }
    }
  `}
`;

type CarouselButtonProps = {
  iconName: string;
  isDisabled: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const CarouselButton = ({ iconName, isDisabled, onClick }: CarouselButtonProps) => {
  return (
    <Button disabled={isDisabled} onClick={onClick}>
      <Icon name={iconName} size={24} />
    </Button>
  );
};
