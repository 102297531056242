import { useDecision } from '@optimizely/react-sdk';
import { PageProps } from 'gatsby';

import { RepairDetailsPage as RepairDetailsPageModule } from '../modules/RepairDetails/RepairDetailsPage';

import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { QuotesPlatformRepairDetailsPage } from '@src/modules/RepairDetails/QuotesPlatformRepairDetailsPage';

const RepairDetailsPage = (props: PageProps) => {
  const [{ enabled: isQuotesPlatform }] = useDecision(
    OptimizelyFeatureFlag.QUOTE_PLATFORM_ROLLOUT,
    { autoUpdate: true }
  );

  if (isQuotesPlatform) {
    return <QuotesPlatformRepairDetailsPage />;
  }

  return <RepairDetailsPageModule {...props} />;
};

export default RepairDetailsPage;
