export const getSessionDate = () => {
  const env = process.env.GATSBY_DD_ENV;
  if (env === 'prod') {
    return null;
  }

  if (typeof window !== 'undefined') {
    const sessionDate = sessionStorage.getItem('mock-date');

    if (sessionDate) {
      return new Date(sessionDate);
    }
  }

  return null;
};

export const getCurrentDate = () => {
  const sessionDate = getSessionDate();

  if (!sessionDate) {
    return new Date();
  }

  return sessionDate;
};
