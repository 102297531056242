import { Heading1, Heading4, Icon, Margin, theme } from '@ovotech/element';
import styled, { css } from 'styled-components';
import React, { forwardRef } from 'react';

import { InnerContainer } from '../ styled';

import { RepairCard } from './RepairCard';

import { mqMediumDown, mqMediumUp } from '@utils/mediaQuery';
import { Quote } from '@entities/types';
import { homeRecoverCoveredItems } from '@fixtures/summaryItems';

const StyledHomeRecoverPlanSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: calc(2 * ${theme.core.space[15]}) ${theme.core.space[3]};

  ${mqMediumDown(`
    padding: 0 0;
    padding: 88px 0 80px 0};
  `)};
`;

const StyledHeading1 = styled(Heading1)`
  font-size: 64px;
  line-height: 95%;
  padding: ${theme.core.space[0]} ${theme.core.space[3]} ${theme.core.space[12]};
`;

const RepairCardContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${theme.core.space[10]};
    padding: 0 ${theme.core.space[3]};
    max-width: 806px;

    ${mqMediumDown(`
        padding-top: ${theme.core.space[8]};
        gap: ${theme.core.space[8]};
        grid-template-columns: 1fr;
        width: 100%;
      `)}
  `
);

const CoveredItem = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${theme.core.space[4]};
    padding: ${theme.core.space[4]};
    border-bottom: solid ${theme.semantic.surface.base} 1px;

    ${mqMediumUp(`
      border-bottom: none;
      border-right: solid ${theme.semantic.surface.base} 1px;
    `)};
  `
);

const CoveredItemWrapperWide = styled.div`
  ${({ theme }) => `
 background-color: ${theme.core.color.brand.white};
`}
`;

const CoveredItemWrapper = styled(InnerContainer)(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: ${theme.core.color.brand.white};
    padding: ${theme.core.space[8]} 0;

    div:last-child {
      border: none;
    }

    ${mqMediumDown(`
        padding-top: ${theme.core.space[6]};
        padding-bottom: ${theme.core.space[6]};
        grid-template-columns: 1fr;
      `)}
  `
);

const IconWrapper = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;
const CoveredItemText = styled(Heading4)`
  ${({ theme }) => `
    color: ${theme.core.color.brand.black};
    font-weight: ${theme.core.fontWeight.bold};
  `}
`;

type HomeRecoverPlanSectionProps = {
  isLoggedIn: boolean;
  repairCost: number;
  onContinue: () => void;
  quoteComplete: Quote;
};

export const HomeRecoverPlanSection = forwardRef<HTMLDivElement, HomeRecoverPlanSectionProps>(
  ({ isLoggedIn, repairCost, onContinue, quoteComplete }, ref) => {
    return (
      <>
        <StyledHomeRecoverPlanSection ref={ref}>
          <StyledHeading1>
            Home Recover {quoteComplete.discountApplied ? 'offer' : 'plans'}.
          </StyledHeading1>
          <RepairCardContainer>
            <RepairCard
              cardVariant="existingCustomer"
              isLoggedIn={isLoggedIn}
              repairCost={repairCost}
              onContinue={onContinue}
              quoteComplete={quoteComplete}
            />
            <RepairCard
              cardVariant="newCustomer"
              isLoggedIn={isLoggedIn}
              repairCost={repairCost}
              onContinue={onContinue}
              quoteComplete={quoteComplete}
            />
          </RepairCardContainer>
        </StyledHomeRecoverPlanSection>
        <CoveredItemWrapperWide>
          <CoveredItemWrapper>
            {homeRecoverCoveredItems.map((item) => {
              return (
                <CoveredItem key={item.id}>
                  <IconWrapper>
                    <Icon name={item.icon} size={32} color={theme.core.color.brand.leaf} />
                  </IconWrapper>
                  <Margin right={2} inline>
                    <CoveredItemText>{item.name}</CoveredItemText>
                  </Margin>
                </CoveredItem>
              );
            })}
          </CoveredItemWrapper>
        </CoveredItemWrapperWide>
      </>
    );
  }
);

HomeRecoverPlanSection.displayName = 'HomeRecoverPlanSection';
