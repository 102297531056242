import styled, { css } from 'styled-components';
import { Badge, CTAButton, Heading3, Heading4, Icon, TextLink } from '@ovotech/element';
import { useSetAtom } from 'jotai';
import { navigate } from 'gatsby';

import { mqMediumUp } from '@utils/mediaQuery';
import { excessAtom, selectedCoverTypeAtom } from '@src/store/store';
import { Quote } from '@entities/types';
import { ROUTE_EV_DETAILS } from '@constants/routes';
import { usePromoComponents } from '@hooks/usePromoComponents';
import { formatPrice } from '@utils/formatPrice';
import { useCustomMixpanel } from '@services/Mixpanel';
import { MixpanelEvents } from '@entities/enums';
import { SupRef } from '@components/Footer';
import { evChargerCoverCancellationsFootnote } from '@constants/footnotes';

type CardProps = {
  withBoxShadow?: boolean;
  isCardTag?: boolean;
};

const Card = styled.div<CardProps>(
  ({ withBoxShadow, isCardTag, theme }) => css`
    width: 100%;
    max-width: 330px;
    background: ${theme.semantic.surface.elevated};
    padding: ${isCardTag ? `48px 20px 24px 20px` : `24px 20px`};
    display: flex;
    flex-direction: column;
    border-radius: 21px;
    box-shadow: ${withBoxShadow && '0px -20px 60px -20px #0B251D80;'};
    position: relative;
    ${mqMediumUp(`
      width: 330px;
      padding: ${isCardTag ? `64px 24px 32px 24px` : `32px 24px`};
    `)};
  `
);

const H3 = styled(Heading3)(
  ({ theme }) => css`
    color: ${theme.semantic.message.base};
    display: flex;
    align-items: baseline;
    font-size: 22px;
    line-height: 28px;
    margin-top: 32px;
    white-space: pre;
    ${mqMediumUp(`
      margin-top: 24px;
    `)};
  `
);

const Price = styled.span(
  ({ theme }) => css`
    color: ${theme.semantic.message.base};
    font-size: 64px;
    line-height: 60px;
  `
);

const H4 = styled(Heading4)(
  ({ theme }) => css`
    color: ${theme.semantic.message.base};
    display: flex;
    align-items: baseline;
    font-size: 22px;
    line-height: 28px;
    margin-top: 12px;
  `
);

const Button = styled(CTAButton)`
  margin-top: 48px;
  ${mqMediumUp(`
    margin-top: 40px;
  `)};
`;

const Link = styled(TextLink)`
  text-align: center;
  cursor: pointer;
  margin-top: 24px;
  ${mqMediumUp(`
    margin-top: 32px;
  `)};
`;

const BadgeContent = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const InfoIcon = styled(Icon)`
  cursor: pointer;
`;

type BenefitCardProps = {
  quote: Quote;
  onExcessInfoClick: () => void;
};

export const CoverCard = ({ quote, onExcessInfoClick }: BenefitCardProps) => {
  const { price, excess, coverType, discountApplied, promotions, terms } = quote;
  const setSelectedCoverType = useSetAtom(selectedCoverTypeAtom);
  const setExcess = useSetAtom(excessAtom);
  const { getEvCoverCardPrice, getEvCoverCardTag } = usePromoComponents();
  const { track } = useCustomMixpanel();

  const handleCoverSelect = () => {
    track(MixpanelEvents.BUTTON_CLICK, {
      buttonId: `${excess}ExcessCTA`,
    });

    setSelectedCoverType(coverType);
    setExcess(excess);
    navigate(ROUTE_EV_DETAILS);
  };

  const getButtonLabel = (excess: number, discountApplied?: boolean) => {
    if (excess > 0 && !discountApplied) {
      return 'Select cover with excess';
    }

    if (excess > 0 && discountApplied) {
      return 'Select offer with excess';
    }

    if (excess === 0 && discountApplied) {
      return 'Select offer without excess';
    }

    return 'Select cover without excess';
  };

  return (
    <Card isCardTag={discountApplied}>
      {discountApplied && promotions.map((promo) => getEvCoverCardTag(promo))}
      <Badge variant="success">
        <BadgeContent>
          £{excess} excess <InfoIcon name="info-dark" size={18} onClick={onExcessInfoClick} />
        </BadgeContent>
      </Badge>

      {!discountApplied && (
        <>
          <H3>
            <Price>{formatPrice(price)}</Price> a month
          </H3>
          <H4>for 12 months</H4>
        </>
      )}
      {discountApplied && promotions.map((promo) => getEvCoverCardPrice(promo, quote))}

      <Button onClick={handleCoverSelect}>{getButtonLabel(excess, discountApplied)}</Button>

      <Link href={terms} target="_blank">
        See terms for cancellation fees
        <SupRef footnote={evChargerCoverCancellationsFootnote} />
      </Link>
    </Card>
  );
};
