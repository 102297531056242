import styled from 'styled-components';
import { Heading3, Margin } from '@ovotech/element';

import { CustomerLogInSection } from './CustomerLogInSection';
import { NotCustomerText } from './NotCustomerText';

import { BorderMuted } from '@components/BorderMuted';
import { SupRef } from '@components/Footer';
import { homeRecoverFreeRepair } from '@constants/footnotes';
import { mqMediumUp } from '@utils/mediaQuery';

const Wrapper = styled('div')`
  ${({ theme }) => `
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0;
    gap: ${theme.core.space[3]}; 
    
    > div:nth-child(odd) {
        flex: 1;
      }
  `}
`;

const FreeRepairFooter = styled.div`
  background-color: ${({ theme }) => theme.core.color.green.lightest};
  width: 100%;
  text-align: center;
  font-weight: ${({ theme }) => theme.core.fontWeight.bold};
`;

const StyledHeading3 = styled(Heading3)`
  ${({ theme }) => `
    color: ${theme.semantic.message.base};
    font-weight: ${theme.core.fontWeight.bold};
  `}
`;

const NotLoggedInContainer = styled('div')`
  ${({ theme }) => `
    border-left: 1px solid ${theme.core.color.neutral.lighter};
   
  `}
`;

const HeadingContainer = styled.div`
  padding: 24px;
  padding-bottom: 0;
`;

const MobileRepairLoginBanner = styled.div`
  ${mqMediumUp(`
    display: none;
  `)};
`;

const DesktopRepairLoginBanner = styled.div`
  display: none;

  ${mqMediumUp(`
    display: block;
  `)};
`;

type RepairLoginBannerProps = {
  freeRepair: boolean;
  onContinue: () => void;
};

export const RepairLoginBanner = ({ onContinue, freeRepair }: RepairLoginBannerProps) => {
  return (
    <>
      <MobileRepairLoginBanner data-testid="mobile-repair-login-banner">
        <HeadingContainer>
          <StyledHeading3>Get a repair +</StyledHeading3>
          <StyledHeading3>our Complete cover.</StyledHeading3>
        </HeadingContainer>

        <Margin vertical={6} />
        <CustomerLogInSection />
        <Margin vertical={6} />
        <BorderMuted />
        <Margin vertical={5} />
        <NotCustomerText onContinue={onContinue} freeRepair={freeRepair} />
        <Margin vertical={3} />
        {freeRepair && (
          <FreeRepairFooter>
            Free initial repair <SupRef footnote={homeRecoverFreeRepair} />
          </FreeRepairFooter>
        )}
      </MobileRepairLoginBanner>

      <DesktopRepairLoginBanner data-testid-id="wrapper">
        <HeadingContainer>
          <StyledHeading3>Get a repair + our Complete cover</StyledHeading3>
        </HeadingContainer>
        <Margin vertical={6} />
        <Wrapper>
          <CustomerLogInSection />
          <NotLoggedInContainer data-testid="not-customer-wrapper">
            <NotCustomerText onContinue={onContinue} freeRepair={freeRepair} />
            {freeRepair && (
              <FreeRepairFooter>
                Free initial repair
                <SupRef footnote={homeRecoverFreeRepair} />
              </FreeRepairFooter>
            )}
          </NotLoggedInContainer>
        </Wrapper>
      </DesktopRepairLoginBanner>
    </>
  );
};
