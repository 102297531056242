import { useCallback, useEffect, useMemo } from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { navigate } from 'gatsby';
import { useAtomValue } from 'jotai';

import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { excessAtom, promoCodeAtom, selectedCoverTypeAtom } from '@src/store/store';
import { CoverType } from '@entities/enums';
import QuoteService from '@services/QuoteService';
import { Quote } from '@entities/types';

const useEvCharger = () => {
  const selectedCoverType = useAtomValue(selectedCoverTypeAtom);
  const excess = useAtomValue(excessAtom);
  const promoCode = useAtomValue(promoCodeAtom);
  const evChargerSelected = selectedCoverType === CoverType.EV_CHARGER_COVER;
  const [evChargerDecision, evChargerClientReady] = useDecision(OptimizelyFeatureFlag.EV_CHARGER);
  const evChargerEnabled = evChargerClientReady ? evChargerDecision.enabled : null;

  const compareByPrice = (quoteA: Quote, quoteB: Quote) => quoteA.price - quoteB.price;

  const filterByCoverType = (quote: Quote) => quote.coverType === CoverType.EV_CHARGER_COVER;

  const isSelectedQuote = useCallback(
    (quote: Quote) => quote.coverType === selectedCoverType && quote.excess === excess,
    [excess, selectedCoverType]
  );

  const quotes = useMemo(() => {
    const allQuotes = QuoteService.getQuote();
    const filteredQuotes = allQuotes.filter(filterByCoverType);
    const sortedQuotes = filteredQuotes.sort(compareByPrice);
    return sortedQuotes;
  }, [promoCode]);

  const selectedQuote = useMemo(() => {
    const quote = quotes.find(isSelectedQuote);
    return quote;
  }, [quotes, isSelectedQuote]);

  useEffect(() => {
    if (evChargerEnabled === false && window.location.pathname.includes('charger-cover')) {
      navigate('/');
    }
  }, [evChargerEnabled]);

  return {
    evChargerEnabled,
    evChargerSelected,
    quotes,
    selectedQuote,
  };
};

export default useEvCharger;
