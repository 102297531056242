import styled from 'styled-components';
import { MouseEvent } from 'react';

import { CarouselButton } from './CarouselButton';

const Root = styled.div`
  display: flex;
  gap: 16px;
`;

type CarouselNavigationProps = {
  isBeginning: boolean;
  isEnd: boolean;
  allowSlidePrev: boolean;
  allowSlideNext: boolean;
  onPrev: (e: MouseEvent<HTMLButtonElement>) => void;
  onNext: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const CarouselNavigation = ({
  isBeginning,
  isEnd,
  allowSlidePrev,
  allowSlideNext,
  onPrev,
  onNext,
}: CarouselNavigationProps) => {
  return (
    <Root>
      <CarouselButton
        iconName="arrow-left"
        isDisabled={isBeginning || !allowSlidePrev}
        onClick={onPrev}
      />
      <CarouselButton
        iconName="arrow-right"
        isDisabled={isEnd || !allowSlideNext}
        onClick={onNext}
      />
    </Root>
  );
};
