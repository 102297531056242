import { PageProps } from 'gatsby';
import React from 'react';
import { useDecision } from '@optimizely/react-sdk';

import { useCustomMixpanel } from '@services/Mixpanel';
import { ConfirmationPage } from '@src/modules';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { QuotesPlatformConfirmationPage } from '@src/modules/Confirmation/QuotesPlatformConfirmationPage';

const Confirmation = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();
  const [{ enabled: isQuotesPlatform }] = useDecision(OptimizelyFeatureFlag.QUOTE_PLATFORM_ROLLOUT);

  if (isQuotesPlatform) {
    return <QuotesPlatformConfirmationPage />;
  }

  return <ConfirmationPage {...props} mixpanel={mixpanel} />;
};

export default Confirmation;
