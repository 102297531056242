import {
  q3FreeMonthsInsuranceFootnotes,
  q2HalfPriceAbsFootnotes,
  buildGenericOfferFootnote,
} from '@constants/footnotes';
import { ProductName } from '@entities/enums';
import { PromoConfig, PromoName } from '@entities/types';
import {
  creditTypeAtom,
  is95ExcessAtom,
  isCreditEligibleAtom,
  isHomeRecoverAtom,
  store,
  tariffNameAtom,
} from '@src/store/store';

export const indirectPromos: PromoConfig[] = [
  {
    order: 1,
    validate: () => {
      const is95Excess = store.get(is95ExcessAtom);
      return !is95Excess;
    },
    name: PromoName.Q1202512MonthsHalfPrice,
    startDate: new Date('2025-01-21T00:00:00'),
    endDate: new Date('2025-02-11T23:59:59'),
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    footnote: buildGenericOfferFootnote({
      endDate: new Date('2025-02-11T23:59:59'),
      id: 'q1-2025-12months-halfprice',
    }),
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 12,
      discountPercentage: 50,
    },
  },
  {
    order: 2,
    name: PromoName.HeadlineOfferThreeMonthsFree,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    startDate: new Date('2024-09-17T00:00Z'),
    endDate: new Date('2024-12-12T23:59:59'),
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsurance,
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 3,
    },
    validate: () => {
      const is95Excess = store.get(is95ExcessAtom);

      return !is95Excess;
    },
  },
  {
    order: 102,
    name: PromoName.HomeRecoverFreeRepair,
    appliesTo: [ProductName.COMPLETE],
    addon: true,
    startDate: new Date('2024-09-17T00:00Z'),
    endDate: new Date('2025-03-20T23:59:59'),
    promoOptions: {
      type: 'FREEREPAIR',
    },
    validate: () => {
      const isHomeRecover = store.get(isHomeRecoverAtom);
      const tariffName = store.get(tariffNameAtom);
      return isHomeRecover === true && tariffName === undefined;
    },
  },
  {
    order: 1,
    name: PromoName.FlashSaleSixMonthsFree,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    startDate: new Date('2024-10-01T00:00Z'),
    endDate: new Date('2024-10-17T23:59:00'),
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsuranceFlashSale,
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 6,
    },
    validate: () => {
      const is95Excess = store.get(is95ExcessAtom);

      return !is95Excess;
    },
  },
  {
    order: 1,
    name: PromoName.BlackFridaySixMonthsFree,
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsuranceBlackFriday,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    startDate: new Date('2024-11-19T00:00Z'),
    endDate: new Date('2024-12-05T23:59:00'),
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 6,
    },
    validate: () => {
      const is95Excess = store.get(is95ExcessAtom);

      return !is95Excess;
    },
  },
  {
    order: 3,
    validate: () => {
      const isHomeRecover = store.get(isHomeRecoverAtom);
      const is95Excess = store.get(is95ExcessAtom);
      const isCreditEligible = store.get(isCreditEligibleAtom);
      const creditType = store.get(creditTypeAtom);

      return Boolean(!isHomeRecover && !is95Excess && isCreditEligible && creditType === 'SVT');
    },
    name: PromoName.EnergyCreditSVT,
    addon: true,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    endDate: new Date('2024-09-17T00:00Z'),
    promoOptions: {
      type: 'ENERGYCREDIT',
      amounts: {
        [ProductName.ESSENTIALS]: 50,
        [ProductName.STARTER]: 50,
        [ProductName.COMPLETE]: 50,
      },
    },
  },
  {
    order: 3,
    validate: () => {
      const isHomeRecover = store.get(isHomeRecoverAtom);
      const is95Excess = store.get(is95ExcessAtom);
      const isCreditEligible = store.get(isCreditEligibleAtom);
      const creditType = store.get(creditTypeAtom);

      return Boolean(!isHomeRecover && !is95Excess && isCreditEligible && creditType === 'FIXED');
    },
    startDate: new Date('2024-02-28T09:00Z'),
    endDate: new Date('2024-09-17T00:00Z'),
    name: PromoName.EnergyCreditFixed,
    addon: true,
    appliesTo: [ProductName.STARTER, ProductName.ESSENTIALS, ProductName.COMPLETE],
    promoOptions: {
      type: 'ENERGYCREDIT',
      amounts: {
        [ProductName.ESSENTIALS]: 100,
        [ProductName.STARTER]: 100,
        [ProductName.COMPLETE]: 100,
      },
    },
  },
  {
    order: 100,
    name: PromoName.Q2AbsOffer,
    startDate: new Date('2024-04-02T00:00Z'),
    endDate: new Date('2024-07-25T23:59:00.000+01:00'),

    appliesTo: [ProductName.ABS],
    footnote: q2HalfPriceAbsFootnotes?.q2HalfPriceAbs,
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
    },
  },
  {
    order: 101,
    name: PromoName.Q120256MonthsHalfPrice,
    startDate: new Date('2025-01-07T00:00Z'),
    endDate: new Date('2025-03-20T23:59:59'),

    appliesTo: [ProductName.COMPLETE, ProductName.ESSENTIALS, ProductName.STARTER],
    footnote: buildGenericOfferFootnote({
      endDate: new Date('2025-03-20T23:59:59'),
      id: 'q1-2025-6months-halfprice',
    }),
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      discountPercentage: 50,
      duration: 6,
    },

    validate: () => {
      const is95Excess = store.get(is95ExcessAtom);

      return !is95Excess;
    },
  },
];
